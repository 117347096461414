import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import HeaderStyles from "../styles/modules/header.module.scss"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <header className={HeaderStyles.header}>
      <h1>
        <Link className={HeaderStyles.title} to="/">
          {data.site.siteMetadata.title}
        </Link>
      </h1>
      <nav className={HeaderStyles.navplacement}>
        <ul className={HeaderStyles.navlist}>
          <li>
            <Link
              className={HeaderStyles.navitem}
              activeClassName={HeaderStyles.active_nav_item}
              to="/"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              className={HeaderStyles.navitem}
              activeClassName={HeaderStyles.active_nav_item}
              to="/blog"
            >
              Blog
            </Link>
          </li>
          <li>
            <Link
              className={HeaderStyles.navitem}
              activeClassName={HeaderStyles.active_nav_item}
              to="/ideas"
            >
              Ideas
            </Link>
          </li>
          <li>
            <Link
              className={HeaderStyles.navitem}
              activeClassName={HeaderStyles.active_nav_item}
              to="/about"
            >
              About
            </Link>
          </li>
          <li>
            <Link
              className={HeaderStyles.navitem}
              activeClassName={HeaderStyles.active_nav_item}
              to="/resume_in"
            >
              Resume
            </Link>
          </li>
          <li>
            <Link
              className={HeaderStyles.navitem}
              activeClassName={HeaderStyles.active_nav_item}
              to="/contact"
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Header
