import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import FooterStyles from "../styles/modules/footer.module.scss"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)
  return (
    <footer className={FooterStyles.footer}>
      Created by {data.site.siteMetadata.author}, © 2020
    </footer>
  )
}

export default Footer
